/*!
Template Name: Anftiland
Template URI: https://www.templatemonster.com/authors/codexcoder/
Author: codexcoder
Author URI: https://www.templatemonster.com/authors/codexcoder/
Description: Description
Version: 1.0.0
Text Domain: Anftiland
Tags:blackfriday, blackfridayoffer, product landing page, product offer, friday landing page, blackfriday product anding page

*/









/*=====================================================================*/

@charset "UTF-8";

// 1. Configuration and helpers
@import 'abstracts/variables',
'abstracts/functions',
'abstracts/mixins';

// 2. Vendors
@import 'vendors/normalize',
'vendors/rfs';

// 3. Base stuff
@import 'base/fonts',
'base/typography',
'base/animations',
'base/helpers';

@import "abstracts/extend";

// 4. Layout-related sections
@import 'layout/header',
'layout/banner',
'layout/contact',
'layout/footer';

// 5. Components
@import 'components/button',
'components/about',
'components/preloader',
'components/gallery',
'components/roadmap',
'components/team',
'components/community',
'components/faq',
'components/rating',
'components/artist',
'components/sale';

// 6. Page-specific styles
@import 'pages/home';

// 7. Themes
@import 'themes/default';