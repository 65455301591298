// -----------------------------------------------------------------------------
// This file contains all application-wide Sass variables.
// -----------------------------------------------------------------------------

$bootstrap-sass-asset-helper: false !default;
//
// Variables
// --------------------------------------------------


// Colors

$title-color: #ffffff;
$theme-color: #8D59FF;
$primary-color: #171835;
$secondary-color:#1EE4FF;
$border-color: #ecf0f3;
$white-color: #fff;
$ash-color: #f9fafb;
$body-color: #060826;
$black-color:#000000;
$text-color: #b3b3b3;
// social-media color
$facebook: #3b5998;
$twitter: #55acee;
$linkedin: #007bb5;
$skype: #2fb7df;
$youtube: #cd201f;
$google: #dd4b39;
$camera: #e4405f;
$instagram: #e4405f;
$tumblr: #34465d;
$behance: #1769ff;
$star: #e9ba17;
$green: #0be881;
$green-h: #0fbcf9;
$red: #f53b57;
$yellow: #dec32b;
$nell: #212fb4;
$navy: #d8e0f3;
$placeholder: #a0a6bc;

//== Typography

// font-family

$text-font: 'Oxanium',
cursive;

$title-font: 'Oxanium',
cursive;

// font-size

$fs-base: 16px !default;
$fs-h1: 72px;
$fs-h2: 55px;
$fs-h3: 48px;
$fs-h4: 24px;
$fs-h5: 20px;
$fs-h6: 18px;



//  transition
$transition: all 0.3s ease;