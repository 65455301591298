// -----------------------------------------------------------------------------
// This file contains CSS helper classes.
// -----------------------------------------------------------------------------

/**
 * Clear inner floats
 */
.clearfix::after {
  clear: both;
  content: '';
  display: table;
}


/**
 * Hide text while making it readable for screen readers
 * 1. Needed in WebKit-based browsers because of an implementation bug;
 *    See: https://code.google.com/p/chromium/issues/detail?id=457146
 */
.hide-text {
  overflow: hidden;
  padding: 0;
  /* 1 */
  text-indent: 101%;
  white-space: nowrap;
}

/**
 * Hide element while making it readable for screen readers
 * Shamelessly borrowed from HTML5Boilerplate:
 * https://github.com/h5bp/html5-boilerplate/blob/master/src/css/main.css#L119-L133
 */
.visually-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

/*=-=-=-=-=-=====> utility classes start here <======= -=-=-=-=-=-=-=*/

.color__theme-color {
  color: $theme-color !important;
}

.padding-top {
  padding-top: 70px;

  @include breakpoint(lg) {
    padding-top: 100px;
  }
}

.padding-bottom {
  padding-bottom: 70px;

  @include breakpoint(lg) {
    padding-bottom: 100px;
  }
}

//gap-10
.g-10 {
  gap: 10px;
}

.bg__gradient {
  background: #8E2DE2;
  /* fallback for old browsers */
  background: -webkit-linear-gradient(to right, #4A00E0, #8E2DE2);
  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, #4A00E0, #8E2DE2);
  /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */


  h1,
  h2,
  h3,
  h4,
  h5,
  h5,
  p,
  a {
    color: $white-color;
  }
}

/*=-=-=-=-=-=====> utility classes end here <======= -=-=-=-=-=-=-=*/